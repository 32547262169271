import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IdphotoPage = () => (
  <Layout>
    <SEO title="証明写真" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../idphoto-header.webp')` }}
    >
      <p className="text-white text-center text-3xl lg:text-4xl self-center">証明写真</p>
    </div>
    <div className="container space-y-5 px-5 lg:px-0 my-8  justify-center content-center">
      <p className="text-textblack text-xl text-center">ご自身を証明するお写真。<br />アライヤ写真館では、お客様一人ひとりに合わせて修整しています。</p>
      <div className="container mx-auto flex flex-col h-[25vh] object-contain">
        <StaticImage
          src="../images/idphoto/idphoto_license.webp"
          imgClassName=" flex-1 aspect-auto h-max w-auto self-center"
          objectFit="contain"
        />
        <p className="text-textblack text-center">当店はID+Photo認定店です。</p>
      </div>
      
      <p className="text-textblack text-center text-lg">当店では撮影した証明写真にプロが修整を行い、ニキビやシワを目立たなく自然に修整します。</p>
      <div className="container mx-auto max-w-screen-md gap-x-2 flex justify-center">
        <div className="h-max">
          <StaticImage
            src="../images/idphoto/idphoto_before.webp"
            ClassName=" flex-1 aspect-auto h-max w-auto self-center"
            objectFit=""
          />
          <p className="text-textblack text-center">修整前</p>
        </div>
        <div className="h-max">
          <StaticImage
            src="../images/idphoto/idphoto_after.webp"
            ClassName=" flex-1 aspect-auto h-max w-auto self-center"
            objectFit=""
          />
          <p className="text-textblack text-center">修整後</p>
        </div>
      </div>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">撮影料金</p>
        <p className="text-textblack font-semibold indent-5">全サイズ共通・修整こみ・焼き増し可・光沢仕上げ</p>
        <p className="text-textblack text-lg indent-5">写真1枚 1650円 &emsp; / &emsp; 2枚以上 プラス 400円/枚</p>
        <p className="text-textblack text-lg indent-5">データ渡し（CDまたはUSBメモリ）プラス 1200円</p>
      </div>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-xl indent-3">焼き増し料金</p>
        <p className="text-textblack text-lg indent-5">1枚 400円 &emsp; （2枚以上プラス300円/枚）</p>
      </div>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        
        <p className="text-slate-500 text-sm indent-3">※価格はすべて税込表示です。</p>
      </div>
      <div className="container mx-auto px-5 max-w-screen-md space-y-3">
        <p className="text-textblack text-2xl underline decoration-2 underline-offset-8">注意事項</p>
        <ul class="list-disc ml-10">
          <li>当日仕上がりは承っておりません。</li>
          <li>焼き増しをご希望の際はお電話でも可能です。その際は、お名前・電話番号・撮影日（おおよそでも結構です。）をお申し付けください。</li>
          <li>細かい規定が記載された紙などが有りましたらお持ちください。</li>
          <li>特殊サイズも受付できます。</li>
          <li>仕上がりは光沢仕上げとなります。</li>
          <li>インドネシア用ビザは赤の背景がございませんので対応できません。ご了承ください。</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default IdphotoPage